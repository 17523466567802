import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEmail, setPassword } from "../Redux/loginSlice";
import { Grid, IconButton, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from 'axios';

function Login() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [userIdError, setUserIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [userIdTouched, setUserIdTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const navigateToSignup = () => {
    navigate("/signup");
  };

  const dispatch = useDispatch();
  const { email: userId, password } = useSelector((state) => state.login);

  const handleUserIdChange = (event) => {
    dispatch(setEmail(event.target.value));  // Still using setEmail to store userId in Redux, can be renamed if needed
    if (!userIdTouched) setUserIdTouched(true);
    validateForm(event.target.value, password);
  };

  const handlePasswordChange = (event) => {
    dispatch(setPassword(event.target.value));
    if (!passwordTouched) setPasswordTouched(true);
    validateForm(userId, event.target.value);
  };

  const validateForm = (userId, password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const isUserIdValid = true;  // You can customize validation for userId here if needed
    const isPasswordValid = true;

    setUserIdError(
      userIdTouched ? (isUserIdValid ? "" : "User ID cannot be empty") : ""
    );
    setPasswordError(
      passwordTouched ? (isPasswordValid ? "" : "Invalid password format") : ""
    );
    setButtonDisabled(!(isUserIdValid && isPasswordValid));
  };

  const handleLogin = async () => {
    try{
    // Call your API here using email and password
    // For example:
    const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/signin", {
    // fetch("https://api.aksharatheschool.com/signin", {
      method: "POST",
      body: JSON.stringify({ email: userId, password }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (!response.ok) {
      throw new Error("Login failed!");
    }

    const data = await response.json();
    localStorage.setItem("authToken", data.token);
    localStorage.setItem("firstName", data.user_details.first_name);
    localStorage.setItem("lastName", data.user_details.last_name);
    localStorage.setItem("refreshToken", data.refresh_token);
    localStorage.setItem("email", data.user_details.email);
    localStorage.setItem("username", data.user_details.username);
    dispatch(setEmail(""))
    dispatch(setPassword(""))
    navigate("/chat");
  } catch (error) {
    console.error(error);
    alert("Login failed. Please try again.");
  }
};

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        md={8}
        lg={8}
        sx={{
          // marginTop: { lg: "11%", md: "20%" },
          marginTop: { lg: "11%", md: "5%" },
        }}
      >
        <div
          // style={BoxStyle}
          className="box_style"
        >
          <div className="signup_heading">
            <h2>Log In </h2>
          </div>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                style={InputStyle}
                type="text"
                placeholder="User ID"
                value={userId}
                onChange={handleUserIdChange}
                onBlur={() => setUserIdTouched(true)}
                error={userIdError !== ""}
              />
              {userIdTouched && <div style={{ color: "red" }}>{userIdError}</div>}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                style={InputStyle}
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={() => setPasswordTouched(true)}
                error={passwordError !== ""}
                // helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setPasswordVisibility(!passwordVisibility)}
                      edge="end"
                    >
                      {passwordVisibility ? (
                        <VisibilityIcon style={{ fontSize: "18px" }} />
                      ) : (
                        <VisibilityOffIcon style={{ fontSize: "18px" }} />
                      )}
                    </IconButton>
                  ),
                }}
              />
              {passwordTouched && (
                <div style={{ color: "red" }}>{[passwordError]}</div>
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <button
                style={{
                  ...LoginButtonStyle,
                  backgroundColor: buttonDisabled ? "#cccccc" : "#234bd6",
                }}
                onClick={handleLogin}
                disabled={buttonDisabled}
              >
                Login
              </button>
            </Grid>
          </Grid>

          <button
            style={ForgotPasswordStyle}
            onClick={() => {
              /* Navigate to Forgot Password */
            }}
          >
            Forgot Password?
          </button>
          <button
            // style={CreateAccountStyle}
            onClick={navigateToSignup}
            className="CreateAccountStyle"
          >
            Create Account
          </button>
        </div>
      </Grid>
    </Grid>
  );
}

const InputStyle = {
  border: "2px solid #234bd6",
  borderRadius: "8px",
  // height: "30px",
};
const BoxStyle = {
  width: "100%",
  backgroundColor: "#EEEEEE",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "10px",
  padding: "0 5rem",
};

const LoginButtonStyle = {
  width: "100%",
  // height: "40px",
  height: "37px",
  // margin: "15px 0px 0px 0px",
  border: "2px solid #234bd6",
  borderRadius: "8px",
  fontSize: "20px",
  backgroundColor: "#234bd6",
  color: "white",
  fontWeight: "bold",
};

const ForgotPasswordStyle = {
  width: "30%",
  height: "30px",
  // margin: "15px 15px 0px 15px",
  marginTop: "-8px",
  fontSize: "15px",
  border: "none",
  color: "#234bd6",
  whiteSpace: "nowrap",
  textAlign: "center  ",
};

const CreateAccountStyle = {
  width: "94%",
  height: "40px",
  margin: "15px 0px 15px 0px",
  border: "2px solid #23d65f",
  borderRadius: "8px",
  fontSize: "20px",
  backgroundColor: "#23d65f",
  color: "white",
  fontWeight: "bold",
};

export default Login;
