import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setEmail,
  setFirstName,
  setLastName,
  setPassword,
} from "../Redux/userSlice";
import { useSelector } from "react-redux";
import { Grid, IconButton, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from "styled-components";

function Signup() {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [userType, setUserType] = useState("");
  const [userTypeTouched, setUserTypeTouched] = useState(false);
  const [userTypeError, setUserTypeError] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirPasswordVisibility, setconfirPasswordVisibility] =
    useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const dispatch = useDispatch();
  const { email, password, firstName, lastName, confirm_password } =
    useSelector((state) => state.user);


  const navigateToLogin = () => {
    navigate("/signin");
  };

  const handleEmailChange = (event) => {
    dispatch(setEmail(event.target.value));
    if (!emailTouched) setEmailTouched(true);
    validateForm(
      event.target.value,
      password,
      firstName,
      lastName,
      userType,
      confirm_password
    );
  };
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value)
    if (!userTypeTouched) setUserTypeTouched(true);
    validateForm(
      event.target.value,
      password,
      firstName,
      lastName,
      userType,
      confirm_password
    );
  };

  const handlePasswordChange = (event) => {
    dispatch(setPassword(event.target.value));
    if (!passwordTouched) setPasswordTouched(true);
    validateForm(
      email,
      event.target.value,
      firstName,
      lastName,
      userType,
      confirm_password
    );
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (!confirmPasswordTouched) setConfirmPasswordTouched(true);
    validateForm(email, password, firstName, lastName, event.target.value);
  };

  const handleFirstNameChange = (event) => {
    dispatch(setFirstName(event.target.value));
    if (!firstNameTouched) setFirstNameTouched(true);
    validateForm(
      email,
      password,
      event.target.value,
      lastName,
      userType,
      confirm_password
    );
  };

  const handleLastNameChange = (event) => {
    dispatch(setLastName(event.target.value));
    if (!lastNameTouched) setLastNameTouched(true);
    validateForm(
      email,
      password,
      firstName,
      userType,
      event.target.value,
      confirm_password
    );
  };

  const validateForm = (
    email,
    password,
    first_name,
    last_name,
    confirm_password,
    userType,
  ) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/;
    const NameRegex = /.{3,}/;

    const isEmailValid = emailRegex.test(email);
    const isPasswordValid = passwordRegex.test(password);
    const isFirstNameValid = NameRegex.test(first_name);
    const isLastNameValid = NameRegex.test(last_name);
    const isUserTypeValid = NameRegex.test(userType);

    setEmailError(
      emailTouched ? (isEmailValid ? "" : "Invalid email format") : ""
    );
    setPasswordError(
      passwordTouched ? (isPasswordValid ? "" :"Password must be at least 6 characters long and include a digit, a special character, an uppercase letter, and a lowercase letter"
      ) : ""
    );
    setFirstNameError(
      firstNameTouched ? (isFirstNameValid ? "" : "Invalid First Name") : ""
    );
    setUserTypeError(
      userTypeTouched ? (isUserTypeValid ? "" : "User type is required") : ""
    );
    setLastNameError(
      lastNameTouched ? (isLastNameValid ? "" : "Invalid Last Name") : ""
    );
    setConfirmPasswordError(
      confirmPasswordTouched
        ? confirm_password === password
          ? ""
          : "Password and Confirm password don't match."
        : ""
    );

    setButtonDisabled(
      !(
        isEmailValid &&
        isPasswordValid &&
        isFirstNameValid &&
        isLastNameValid &&
        password === confirm_password
      )
    );
  };

  const handleSignup = () => {
    fetch(process.env.REACT_APP_API_BASE_URL + "/signup", {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        userType
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json().then((data) => ({ status: response.status, data }))) // Capture both status and data
    .then(({ status, data }) => {
      console.log(data)
      if (status === 200) {
        alert("You have successfully registered. You can now log in to the application")
        dispatch(setFirstName(""))
        dispatch(setLastName(""))
        dispatch(setEmail(""))
        dispatch(setPassword(""))
        setConfirmPassword("")
        navigate("/signin");
      }else if(status === 400){
        alert(data.message)
      }
    })
      .catch((error) => {
        // Handle error
        console.error(error);
        alert("Signup failed. Please try again."); // Show error popup
      });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      // style={SignupStyle}
      className="signup_style"
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          // marginTop: { lg: "10%", md: "5%" },
          marginTop: { lg: "10%", md: "2%" },
        }}
      >
        <div style={BoxStyle}>
          <div className="signup_heading">
            <h2>Sign Up</h2>
          </div>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                style={InputStyle}
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={handleFirstNameChange}
                onBlur={() => setFirstNameTouched(true)}
              />
              {firstNameTouched && (
                <div style={{ color: "red" }}>{firstNameError}</div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                style={InputStyle}
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={handleLastNameChange}
                onBlur={() => setLastNameTouched(true)}
              />
              {lastNameTouched && (
                <div style={{ color: "red" }}>{lastNameError}</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                style={InputStyle}
                type="text"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                onBlur={() => setEmailTouched(true)}
              />
              {emailTouched && <div style={{ color: "red" }}>{emailError}</div>}
            </Grid>
            <Grid item xs={12}>
  <TextField
    select
    fullWidth
    style={InputSelectStyle}
    // label="Select User Type"
    value={userType}
    onChange={handleUserTypeChange}
    onBlur={() => setUserTypeTouched(true)}
    SelectProps={{
      native: true,
    }}
  >
    <option value="">User Type</option> 
    <option value="teacher">Teacher</option>
    <option value="student">Student</option>
  </TextField>
  {userTypeTouched && <div style={{ color: "red" }}>{userTypeError}</div>}
</Grid>
            <Grid item xs={12}>
              <TextField
                style={InputStyle}
                fullWidth
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={() => setPasswordTouched(true)}
                error={passwordError !== ""}
                // helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setPasswordVisibility(!passwordVisibility)}
                      edge="end"
                    >
                      {passwordVisibility ? (
                        <VisibilityIcon style={{ fontSize: "18px" }} />
                      ) : (
                        <VisibilityOffIcon style={{ fontSize: "18px" }} />
                      )}
                    </IconButton>
                  ),
                }}
              />
              {passwordTouched && (
                <div style={{ color: "red" }}>{passwordError}</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                style={InputStyle}
                type={confirPasswordVisibility ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={() => setConfirmPasswordTouched(true)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setconfirPasswordVisibility(!confirPasswordVisibility)
                      }
                      edge="end"
                    >
                      {confirPasswordVisibility ? (
                        <VisibilityIcon style={{ fontSize: "18px" }} />
                      ) : (
                        <VisibilityOffIcon style={{ fontSize: "18px" }} />
                      )}
                    </IconButton>
                  ),
                }}
              />
              {confirmPasswordTouched && (
                <div style={{ color: "red" }}>{confirmPasswordError}</div>
              )}
            </Grid>
          </Grid>
          <button
            style={{
              ...CreateAccountButtonStyle,
              backgroundColor: buttonDisabled ? "#cccccc" : "#234bd6",
            }}
            onClick={handleSignup}
            disabled={buttonDisabled}
          >
            Sign Up
          </button>
          <div className="LoginTextStyle">
            Already have an account?{" "}
            <span style={LoginLinkStyle} onClick={navigateToLogin}>
              Sign In
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const SignupStyle = {
  width: "100%",
  padding: "0 10rem",
  // paddingRight: "0rem",
};

const BoxStyle = {
  backgroundColor: "#EEEEEE",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "10px",
  padding: "20px",
};

const InputStyle = {
  border: "2px solid #234bd6",
  borderRadius: "8px",
};
const InputSelectStyle = {
  border: "2px solid #234bd6",
  borderRadius: "8px",
  // height:"50px"
};

const CreateAccountButtonStyle = {
  width: "100%",
  // height: "40px",
  height: "37px",
  margin: "15px 0",
  border: "2px solid #234bd6",
  borderRadius: "8px",
  fontSize: "20px",
  backgroundColor: "#234bd6",
  color: "white",
  fontWeight: "bold",
};

const LoginTextStyle = {
  textAlign: "center",
  margin: "10px 0",
};

const LoginLinkStyle = {
  color: "#234bd6",
  cursor: "pointer",
};

export default Signup;
