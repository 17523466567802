import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  LinearProgress,
  Grid,
  Dialog,
  Typography,
  IconButton,
} from "@mui/material";
import { Check as FaCheck, Close as FaTimes } from "@mui/icons-material";
import axios from "axios";

const AssignmentQuiz = ({ openQuiz, assignmentName, onClose }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const handleQuiz = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_BASE_URL}/quiz/${assignmentName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error in fetching: ", error);
      throw error;
    }
  };

  const handleAnswerClick = (optionIndex) => {
    setSelectedAnswer(optionIndex);
    setShowResult(true);

    const isCorrect =
      questions[currentQuestion].options[optionIndex] ===
      questions[currentQuestion].correct_answer;

    if (isCorrect) {
      setScore(score + 1);
    }
  };

  const handleNextQuestion = () => {
    setShowResult(false);
    setSelectedAnswer(null);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowScore(true);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setSelectedAnswer(null);
    setShowResult(false);
  };

  const getProgressValue = () => {
    return questions.length > 0
      ? ((currentQuestion + 1) / questions.length) * 100
      : 0;
  };

  useEffect(() => {
    handleQuiz();
  }, []);

  return (
    <Dialog open={openQuiz} fullWidth>
      <Container className="quiz-container">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: "16px",
            top: "16px",
            zIndex: 1000,
          }}
        >
          <FaTimes />
        </IconButton>

        <Card className="quiz-card">
          <CardHeader className="quiz-header" title="Quiz App" />
          <CardContent>
            {showScore ? (
              <div className="score-section">
                <Typography variant="h5" component="h2">
                  Your Score: {score} out of {questions.length}
                </Typography>
                <Typography variant="body1" className="percentage">
                  Percentage: {((score / questions.length) * 100).toFixed(1)}%
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={restartQuiz}
                  className="restart-btn"
                >
                  Restart Quiz
                </Button>
              </div>
            ) : questions.length > 0 ? (
              <div className="question-section">
                <div className="progress-section">
                  <Typography variant="body2" className="question-count">
                    Question {currentQuestion + 1}/{questions.length}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={getProgressValue()}
                    className="quiz-progress"
                  />
                </div>

                <Typography variant="h6" className="question-text">
                  {questions[currentQuestion].question}
                </Typography>

                <Grid container spacing={2} className="options-container">
                  {questions[currentQuestion].options.map((option, index) => (
                    <Grid item xs={12} key={index}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className={`option-btn ${
                          showResult
                            ? option === questions[currentQuestion].correct_answer
                              ? "correct"
                              : selectedAnswer === index
                              ? "incorrect"
                              : ""
                            : ""
                        }`}
                        onClick={() => handleAnswerClick(index)}
                        disabled={showResult}
                      >
                        <span className="option-text">{option}</span>
                        {showResult &&
                          option === questions[currentQuestion].correct_answer && (
                            <FaCheck className="result-icon correct" />
                          )}
                        {showResult &&
                          index === selectedAnswer &&
                          option !== questions[currentQuestion].correct_answer && (
                            <FaTimes className="result-icon incorrect" />
                          )}
                      </Button>
                    </Grid>
                  ))}
                </Grid>

                {showResult && (
                  <div className="next-button-container">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextQuestion}
                    >
                      {currentQuestion === questions.length - 1
                        ? "Show Results"
                        : "Next Question"}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <Typography variant="h6">Loading quiz...</Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </Dialog>
  );
};

export default AssignmentQuiz;
