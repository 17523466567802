import { Box, Button, Drawer, IconButton } from "@mui/material";
import { Form, Formik, Field } from "formik";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import axios from "axios";
import Table from "../utils/Table";
import TeacherTable from "./TeacherTable";
import StudentAssistanceOptions from "./StudentAssistance";

const TecaherPromptsDrawer = ({
  isOPen,
  onTecaherDrawerClose,
  teacherPrompt,
}) => {
  const userType = localStorage.getItem("userType")
  const [isRetrieve, setIsRetrieveAssign] = useState(false);
  const [retrieveAssign, setRetrieveAssign] = useState([]);
  const [isTeachers, setIsTeachers] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [teachersData, setTeachersData] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [asnaOrResp,setasnaOrResp] = useState("")
  const [videoUrl,setVideoUrl] = useState("")
  const [assignmentName,setAssignmentName] = useState("")

  const handleRetrieve = async (selectedCourse) => {
    setIsRetrieveAssign((prev) => !prev);
    const token = localStorage.getItem("authToken");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/assgn_list/${encodeURIComponent(
          selectedCourse
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.data;
      setRetrieveAssign(data.assignments);
    } catch (error) {
      console.error("Error in fetching: ", error);
      throw error;
    }
  };

  const handleSubmit = async (values) => {
    const token = localStorage.getItem("authToken");
    setAssignmentName(values.assignment)
    try {
      const postData = {
        course: values.selectedCourse,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/hw_eval/${values.assignment}`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTeachersData(response.data.student_results);
      setCommonData(response.data.common_details);
      setasnaOrResp(response.data.matching_file_path)
      setVideoUrl(response.data.extra_path)

      if(userType === "1") {
        setIsTeachers((prev) => !prev);
      }else if(userType === "5") {
        setIsStudent((prev) => !prev);
      }
    } catch (error) {
      console.error("Error in submitting: ", error);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOPen}
        onClose={onTecaherDrawerClose}
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          style={{ width: "650px", padding: "20px" }}
          role="presentation"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <IconButton
            onClick={onTecaherDrawerClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Formik
            initialValues={{ selectedCourse: "", assignment: "" }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "40px",
                }}
              >
                {teacherPrompt.courses && (
                  <>
                    <Field
                      name="selectedCourse"
                      as="select"
                      value={values.selectedCourse}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: "100%", padding: "8px" }}
                    >
                      <option value="" disabled>
                        Select a course
                      </option>
                      {teacherPrompt.courses.map((course, index) => (
                        <option key={index} value={course.Course_List}>
                          {course.Course_List}
                        </option>
                      ))}
                    </Field>

                    <Link onClick={() => handleRetrieve(values.selectedCourse)}>
                      Retrieve Assignments
                    </Link>

                    {errors.selectedCourse && touched.selectedCourse && (
                      <div className="error_message">
                        {errors.selectedCourse}
                      </div>
                    )}
                  </>
                )}

                {isRetrieve && (
                  <>
                    <Field
                      name="assignment"
                      as="select"
                      value={values.assignment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: "100%", padding: "8px" }}
                    >
                      <option value="" disabled>
                        Select an assignment
                      </option>
                      {Array.isArray(retrieveAssign) &&
                        retrieveAssign.map((assignment, index) => (
                          <option key={index} value={assignment.id}>
                            {assignment.Assignment_Name}
                          </option>
                        ))}
                    </Field>
                  </>
                )}

                <Button type="submit" variant="outlined">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>

          {isTeachers && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                margin: "20px 0 20px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <p>
                  <b>Course Name:</b> {commonData.course_name}
                </p>
                <p>
                <b>Assigned Date:</b>
                  {new Date(commonData.assigned_date).toLocaleDateString()}
                </p>
                
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                 <p>
                  <b>Assignment Name:</b> {commonData.assignment_name}
                </p>
                  
                <p>
                  <b>Due Date:</b>
                  {new Date(commonData.due_date).toLocaleDateString()}
                </p>
              </div>
            </div>
          )}
          {isTeachers &&  <TeacherTable teachersData={teachersData} />}
          {isStudent  && <StudentAssistanceOptions asnaOrResp={asnaOrResp} videoUrl={videoUrl} assignmentName={assignmentName} onClose={onTecaherDrawerClose}/>}
        </Box>
      </Drawer>
    </>
  );
};

export default TecaherPromptsDrawer;
