import React from "react";
import Table from "../utils/Table";
import { Modal, Box, IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";
import CircularProgress from '@mui/material/CircularProgress';



const TeacherTable = ({ teachersTable,teachersData,commonData,handleClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height:500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border:"none",
    borderRadius:"8px"
  };
  // Format the teachers data to include a fullName property
  const formattedTeachersData = Array.isArray(teachersData)
    ? teachersData.map((teacher) => ({
        ...teacher,
        fullName: `${teacher.givenname} ${teacher.surname}`, // Combine given name and surname
      }))
    : [];

  const columns = [
    {
      field: "fullName",
      headerName: "Full Name",
      editable: true,
      flex: 1,
    },
    {
      field: "submitted_date",
      headerName: "Submitted Date",
      type: "date",
      editable: true,
      flex: 1,
      valueGetter: (params) => new Date(params),
    },
    {
      field: "evaluation",
      headerName: "Remarks",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Market", "Finance", "Development"],
      flex: 1,
    },
  ];

  return (
    <>
      <Modal
  open={teachersTable}
  onClose={handleClose}
  BackdropProps={{
    onClick: (event) => event.stopPropagation(),
  }}
>
{formattedTeachersData.length > 0 ? 
(<>
  <Box sx={{ ...style, position: "relative" }}>
    <IconButton
      onClick={handleClose}
      sx={{
        position: "absolute",
        top: -2,
        right: -2,
        zIndex: 1,
      }}
    >
      <IoClose style={{fontSize:"30px"}}/>
    </IconButton>
    <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                margin: "20px 0 20px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <p>
                  <b>Course Name:</b> {commonData.course_name}
                </p>
                <p>
                <b>Assigned Date:</b>
                  {new Date(commonData.assigned_date).toLocaleDateString()}
                </p>
                
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                 <p>
                  <b>Assignment Name:</b> {commonData.assignment_name}
                </p>
                  
                <p>
                  <b>Due Date:</b>
                  {new Date(commonData.due_date).toLocaleDateString()}
                </p>
              </div>
            </div>
    <Table
      rows={formattedTeachersData}
      columns={columns}
      getRowId={(row) => row.id}
    />
  </Box></>) : (
    <>
         <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
         <CircularProgress />
         </div>
</>
  )}
</Modal>
    </>
  );
};

export default TeacherTable;
