import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Header = ({ onLanguageToggle }) => {
  const [username, setUsername] = useState("");
  const [isMultilingual, setIsMultilingual] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem("authToken"); // Assuming username is stored directly
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const handleLanguageToggle = () => {
    setIsMultilingual((prev) => {
      const newValue = !prev; // Toggle the multilingual flag
      if (onLanguageToggle) {
        onLanguageToggle(newValue); // Pass the updated value to the parent
      }
      return newValue; // Update the state
    });
  };

  return (
    <HeaderContainer>
      <LeftContainer>
        {username && <UsernameText>{username}</UsernameText>}
      </LeftContainer>
      <TitleContainer>
        <Title>Welcome to Pulsera</Title>
      </TitleContainer>
      <RightContainer>
        <ToggleContainer>
          <Title>English</Title>
          <ToggleSwitch>
            <input
              type="checkbox"
              checked={isMultilingual}
              onChange={handleLanguageToggle}
            />
            <Slider />
          </ToggleSwitch>
          <Title>Multilingual</Title>
        </ToggleContainer>
      </RightContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #002f50;
  color: #fff;
  padding: 10px 200px;
  height: 5vh;
  width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`;

const UsernameText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  gap: 5px;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;
  margin: 0 5px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + span:before {
    transform: translateX(20px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export default Header;
