import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export default function Table({ rows, columns }) {
  return (
    <Box
      sx={{
        maxHeight: "300px",
        width: "100%",
        overflow:"auto",
        bgcolor: 'background.paper',
        border:"none",
        boxShadow:2,
        borderRadius:"8px",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        editMode="row"
      />
    </Box>
  );
}
