import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AssignmentQuiz from './AssignmentQuiz';

const StudentAssistanceOptions = ({ asnaOrResp, videoUrl,assignmentName,onClose }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const[openQuiz,setOpenQuiz] = useState(false)
  const [quizData,setQuizData] = useState([])

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const loadQuiz = async() => {
    try{
    // onClose()
    const token = localStorage.getItem("authToken");
    const response = await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/${assignmentName}`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    if(response.data.status === "success"){
        setOpenQuiz((prev) => !prev)
        setQuizData(response.data)
    }
    } catch (error) {
      console.error("Error in fetching:", error);
      throw error;
    }

  };

  const handlePdfView = async () => {
    const token = localStorage.getItem("authToken");

    try {
      const pdf_path = {
        file_path: asnaOrResp,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/get_file`,
        method: "POST",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: pdf_path,
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "Concept.pdf"; // Provide a default filename for the downloaded PDF
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // Release the blob URL after download

    } catch (error) {
      console.error("Error in fetching:", error);
      throw error;
    }
  };

  return (
    <>
    <div style={accordionStyles.container}>
      {/* Concept PDF Option */}
      <div style={accordionStyles.item}>
        <div style={accordionStyles.title} onClick={() => toggleAccordion(0)}>
          Concept PDF
        </div>
        {openIndex === 0 && (
          <div style={accordionStyles.content}>
            <p>Download this PDF to understand the underlying concepts better.</p>
            <button onClick={handlePdfView}>Download PDF</button>
          </div>
        )}
      </div>

      {/* Video Explanation Option */}
      <div style={accordionStyles.item}>
        <div style={accordionStyles.title} onClick={() => toggleAccordion(1)}>
          Video Explanation
        </div>
        {openIndex === 1 && (
          <div style={accordionStyles.content}>
            <p>Watch this video to gain a clearer understanding of the topic.</p>
            <Link to={videoUrl} target="_blank">
              Watch Video
            </Link>
          </div>
        )}
      </div>

      {/* Practice Quiz Option */}
      <div style={accordionStyles.item}>
        <div style={accordionStyles.title} onClick={() => toggleAccordion(2)}>
          Practice Quiz
        </div>
        {openIndex === 2 && (
          <div style={accordionStyles.content}>
            <p>Complete this quiz to confirm your understanding.</p>
            <button onClick={() => loadQuiz('quiz_12345')}>Start Quiz</button>
          </div>
        )}
      </div>

      {/* Schedule Session with Teacher Option */}
      <div style={accordionStyles.item}>
        <div style={accordionStyles.title} onClick={() => toggleAccordion(3)}>
          Schedule Session with Teacher
        </div>
        {openIndex === 3 && (
          <div style={accordionStyles.content}>
            <p>If you need more help, you can schedule a session with your teacher.</p>
            <a href="https://calendly.com/your-teacher-link" target="_blank" rel="noopener noreferrer">
              Schedule on Calendly
            </a>
          </div>
        )}
      </div>
    </div>
    {openQuiz && <AssignmentQuiz openQuiz={openQuiz} assignmentName={assignmentName}/>}
    </>
  );
};

const accordionStyles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  item: {
    borderBottom: '1px solid #dddddd',
    padding: '10px 0',
  },
  title: {
    padding: '15px',
    fontSize: '18px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#ffffff',
  },
  content: {
    padding: '15px',
    backgroundColor: '#f1f1f1',
  },
};

export default StudentAssistanceOptions;
