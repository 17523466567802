import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Chatbot from "./Chatbot"; // Assuming the Chatbot component is ready to accept 'isMultilingual'
import { Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ChatList from "./ChatList"

function ChatBox() {
  const { base, chatId, username } = useParams();
  //const [isMultilingual, setIsMultilingual] = useState(false);

  // Handle storing the username in localStorage if passed
  useEffect(() => {
    localStorage.setItem("isMultilingual", "disabled");
    if (username) {
      console.log("Username from URL:", username); // Debugging line
      const storedUsername = localStorage.getItem("authToken");
      if (storedUsername !== username) {
        localStorage.setItem("authToken", username);
        window.location.reload(); // Store username in localStorage only if it changes
      }
    }
  }, [username]);

  // Choose whether to use 'base' or 'username'
  const effectiveBase = base || username || localStorage.getItem("authToken") || "defaultBase"; // Use 'username' first

  
// Function to update `isMultilingual` in localStorage
const handleLanguageToggle = (newValue) => {
  // Store true/1 for multilingual enabled, or false/0 for disabled
  localStorage.setItem("isMultilingual", newValue ? "enabled" : "disabled");
};

  return (
    <>
      <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
          {/* Pass language toggle handler to Header */}
          <Header onLanguageToggle={handleLanguageToggle} />
        </Grid>
      </Grid>

      {/* Render chat based on the 'base' value */}
      {effectiveBase === 'cra' ? (
        <Grid container spacing={0}>
          <Grid item xs={12} md={2.8}>
            <ChatList />
          </Grid>
          <Grid item xs={12} md={9.2}>
          <Paper>
              {/* Pass 'isMultilingual' state to Chatbot */}
              {/* <Chatbot isMultilingual={isMultilingual} /> */}
               <Chatbot  />
              {/* <p>{`Current language setting: ${isMultilingual ? 'Enabled' : 'Disabled'}`}</p> */}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} md={2.8}>
            <ChatList />
          </Grid>
          <Grid item xs={12} md={9.2}>
          <Paper>
          <Chatbot  />
              {/* <p>{`Current language setting: ${isMultilingual ? 'Enabled' : 'Disabled'}`}</p> */}
            </Paper>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Footer /> {/* Footer component */}
        </Grid>
      </Grid>
    </>
  );
}

// Styled components for layout (optional)
const ChatBoxContainer = styled.div`
  display: flex;
  height: 90vh;
  background-color: white;
`;

const ChatBoxFullContainer = styled.div`
  height: 100vh;
  background-color: white;
`;

export default ChatBox;
